<template>
  <div id="staff-assign-history">
    <!-- BREADCRUMB -->
    <breadcrumb-base :breadcrumb="breadcrumb" title="StaffHistory">
      <template v-slot:right-end>
      </template>
    </breadcrumb-base>


    <transition name="fade">
      <div class="vx-row">
        <div class="vx-col md:w-1/5 mt-20">
          <vx-card>
            <div class="vs-col ">
<!--              TODO: Add manager and referer-->


              <div class="vx-col w-full mt-3" v-if="showMangerDropDown">

                <label style="font-size: 12px; font-weight: bold">{{ $t('Manager') }}
                  <span
                    @click="selectedManager = null;filterChanged()"
                    class="span-clear cursor-pointer">{{ $t("Clear") }}</span>
                </label>
                <vs-select
                  autocomplete
                  class="pr-2 w-full pb-3"
                  v-model="selectedManager"
                  @input="filterChanged(-1)"
                  :placeholder="$t('Manager')"
                >
                  <vs-select-item key="all_referer" :value="null" :text="$t('All')"/>
                  <vs-select-item :key="index" :value="customer" :text="customer.user_name"
                                  v-for="(customer,index) in usersRaw.manager_accounts"/>
                </vs-select>
              </div>
              <div class="vx-col w-full mt-3" v-if="showRefererDropDown">
                <label style="font-size: 12px; font-weight: bold">{{ $t('Staff') }}
                  <span
                    @click="selectedReferer = null;filterChanged()"
                    class="span-clear cursor-pointer">{{ $t("Clear") }}</span>
                </label>
                <vs-select
                  autocomplete
                  class="pr-2 w-full pb-3"
                  v-model="selectedReferer"
                  @input="filterChanged"
                  :placeholder="$t('Staff')"
                >
                  <vs-select-item key="all_referer" :value="null" :text="$t('All')"/>
                  <vs-select-item :key="index" :value="customer.user_id" :text="customer.user_name"
                                  v-for="(customer,index) in staffAccounts"/>
                </vs-select>
              </div>

              <div class="py-2"></div>
            </div>


            <div class="py-4"></div>
            <div class="vx-col mt-4 m-3"
                 :key="index"
                 v-for="(item, index) in Object.keys(staffFilters)">
              <vs-row class="vs-row">
                <h4>{{item | title}}</h4>
                <vs-button class="ml-2" v-if="filterSelectData[item] != null" color="warning"
                           type="flat" icon-pack="feather" icon="icon-x" size="small"
                           @click="clearFilter(item)">{{ $t('Clear') }}
                </vs-button>
              </vs-row>
              <ul class="centerx mt-4">
                <li :key="index" v-for="(option, index) in staffFilters[item]">
                  <vs-radio v-model="filterSelectData[item]" v-on:change="filterChanged"
                            :vs-value="option.id.toString() + item">{{option.name | title}}
                  </vs-radio>
                </li>
              </ul>
              <vs-divider />
            </div>

            <!--            From TO Date Filters -->
            <h4>{{$t('FromDate') | title}}</h4>
            <div class="py-2"></div>
            <datepicker :placeholder="$t('FromDate')"
                        :disabled-dates="{from: new Date()}"
                        v-model="startDate" v-on:select="filterChanged"></datepicker>
            <div class="py-2"></div>
            <h4>{{$t('ToDate') | title}}</h4>
            <div class="py-2"></div>
            <datepicker :placeholder="$t('ToDate')"
                        v-model="endDate" :disabled-dates="disabledDates"></datepicker>
            <div class="py-2"></div>


            <vs-divider />

            <vs-button v-if="filterSelectData" class="mt-3 w-full py-4 px-3" color="primary"
                       @click="filterChanged">{{$t('Filter').toUpperCase()}}
            </vs-button>
            <div class="py-2"></div>
            <vs-button v-if="filterSelectData" class="mt-3 w-full py-4 px-3" color="primary"
                       @click="clearFilterData">{{$t('ClearAllFilters').toUpperCase()}}
            </vs-button>
          </vx-card>
        </div>
        <div class="vx-col md:w-4/5">

          <!--    Empty List State -->
          <transition name="fade">
            <div class="h-screen flex w-full bg-img" v-if="staffHistory.length === 0">
              <div
                class="vx-col flex items-center justify-center flex-col sm:w-1/2 md:w-3/5 lg:w-3/4 xl:w-1/2 mx-auto text-center">
<!--                <img src="@/assets/images/pages/svg_blank.svg" :alt="$t('NoData')"-->
<!--                     class="mx-auto mb-4 max-w-full">-->
                <h1 class="sm:mx-0 mx-4 mb-12 text-3xl">{{$t('NoData')}}</h1>
              </div>
            </div>
          </transition>
          <vs-table-modified v-show="staffHistory.length > 0" ref="table"
                             :max-items="20"
                             :data="staffHistory"
          >
            <template slot="thead">
              <vs-th  >{{$t('Date')}}</vs-th>
              <vs-th  >{{$t('Manager')}}</vs-th>
              <vs-th >{{$t('User')}}</vs-th>
              <vs-th >{{$t('Operation')}}</vs-th>
            </template>
            <template slot-scope="{data}">
              <tbody>
              <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">


                <vs-td>
                  <p class="product-name font-medium truncate">{{ tr.created | dateFormat }}</p>
                </vs-td>

                <vs-td>
                  <p class="product-name font-medium truncate">{{ tr.manager }}</p>
                </vs-td>

                <vs-td>
                  <p class="product-name">{{ tr.staff }}</p>
                </vs-td>

                <vs-td>
                  <p class="product-type" :class="tr.mode == 0 ? 'text-success': 'text-danger'">{{ tr.mode == 0 ? $t('Add') : $t('Delete') }}</p>
                </vs-td>

              </vs-tr>
              </tbody>
            </template>
            <template slot="footer">
            </template>
            <template slot="footer-left">
              <div class="flex">
                <vs-new-pagination :total="vTotalPages" v-model="cPage" v-on:change="listPageChanged">
                </vs-new-pagination>
              </div>
            </template>
          </vs-table-modified>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker'
import moment from 'moment'

export default {
  name: 'StaffAssignHistory',
  components: {
    Datepicker,
  },
  data() {
    return {
      managerAccounts: [],
      selectedManager: null,
      selectedReferer: null,
      filterSelectData: {},
      cPage: 1,
      staffHistory: [],
      staffFilters: {},
      filters: {},
      startDate: null,
      endDate: null,
      breadcrumb: [
        { title: 'Home', i18n: 'Home', url: '/' },
        { title: 'Staff History', i18n: 'StaffHistory', active: true },
      ],
    }
  },
  computed: {
    vTotalPages() {
      let page = 1
      if (Object.keys(this.filters).length > 0) {
        page = Math.ceil(Number(this.filters.total_count) / Number(this.filters.limit))
      }
      console.log(page)
      return page
    },
    disabledDates() {
      if (!this.startDate) {
        return {
          from: new Date(),}
      }
      return {
        to: this.startDate,
        from: new Date(),
      }
    },

    showMangerDropDown() {
      return this.activeUserInfo.internal_role === 0;
    },
    showRefererDropDown() {
      return this.activeUserInfo.internal_role === 0 || this.activeUserInfo.staff_role === 1;
    },
    activeUserInfo() {
      return this.$store.state.AppActiveUser
    },
    staffAccounts() {
      if (this.selectedManager != null) {
        return this.selectedManager.staff_accounts
      } else {
        return this.usersRaw.staff_accounts;
      }
    },
    usersRaw() {
      return this.$store.state.user.usersRaw || {}
    },
  },
  methods: {
    clearFilterData() {
      this.filterSelectData = {}
      this.selectedReferer = null
      this.selectedManager = null
      this.fetchStaffAssignHistory('0')
    },
    clearFilter(item) {
      this.filterSelectData[item] = null

      this.fetchStaffAssignHistory('0')
    },
    listPageChanged() {
      this.fetchStaffAssignHistory(this.cPage - 1)
    },
    getFilters() {
      const filter = {}
      Object.keys(this.filterSelectData).forEach((key) => {
        if (this.filterSelectData[key] != null) {
          filter[key] = this.filterSelectData[key].replace(key, '')
        }
      })
      if (this.selectedManager) {
        filter.manager_id = this.selectedManager.user_id
      }
      if (this.selectedReferer) {
        filter.staff_id = this.selectedReferer
      }
      if (this.startDate) {
        filter.start_date = moment(this.startDate).format('yyyy-MM-DD')
      }
      if (this.endDate) {
        filter.end_date = moment(this.endDate).format('yyyy-MM-DD')
      }
      return filter
    },
    filterChanged() {
      this.fetchStaffAssignHistory('0')
    },
    fetchStaffAssignHistory(page = 0) {
      const payload = this.getFilters()
      this.$store.dispatch('user/fetchStaffHistory', { page, payload }).then((data) => {
        this.staffHistory = data.data.history
        this.staffFilters = data.data.types_of_filters
        this.filters = data.data.filters
      }).catch((error) => {
        this.$vs.loading.close()
        console.log(error)
        this.errorFetching = true
        this.$vs.notify({
          position: 'top-center',
          title: 'Error',
          text: error.response.data.message || error.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        })
      })
    },

    loadUsers() {
      this.$vs.loading()
      this.$store.dispatch('user/fetchUsers', {})
        .then((data) => {
          this.managerAccounts = data.data.manager_accounts
          this.$vs.loading.close()
          this.errorFetching = false
        })
        .catch((error) => {
          this.$vs.loading.close()
          this.errorFetching = true
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: error.response.data.message || error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },
  },
  mounted() {
    this.fetchStaffAssignHistory()
    this.loadUsers()
  },
}
</script>

<style lang="scss">
#staff-assign-history {
  .vs-con-table {

    /*
      Below media-queries is fix for responsiveness of action buttons
      Note: If you change action buttons or layout of this page, Please remove below style
    */
    @media (max-width: 689px) {
      .vs-table--search {
        margin-left: 0;
        max-width: unset;
        width: 100%;

        .vs-table--search-input {
          width: 100%;
        }
      }
    }

    @media (max-width: 461px) {
      .items-per-page-handler {
        display: none;
      }
    }

    @media (max-width: 341px) {
      .customer-list-btn-container {
        width: 100%;

        .dd-actions,
        .btn-add-new {
          width: 100%;
          margin-right: 0 !important;
        }
      }
    }

    .supplier-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap;
      margin-left: 1.5rem;
      margin-right: 1.5rem;

      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 .2rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, .05);

        td {
          padding: 8px 2px;

          &:first-child {
            border-top-left-radius: .5rem;
            border-bottom-left-radius: .5rem;
          }

          &:last-child {
            border-top-right-radius: .5rem;
            border-bottom-right-radius: .5rem;
          }
        }

        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }

      th.td-check {
        padding: 0 15px !important;
      }

      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>
